
const headers = (t : any) => {
    return [


        {
            label: 'Type',
            className: "w-30p"
        },
        {
            label: "Frequency",
            className: "w-30p"
        },
        {
            label: "status",
            className: "w-5p"
        },
        {
            label: "",
            className: "w-5p"
        },

    ]
}

export const ScheduleConfig = {
    headers,
}
