import React, {useEffect, useState} from "react";
import QTextBox from "../../../../common/components/QTextBox";
import QSelect from "../../../../common/components/QSelect";
import UserApi from "../../../../common/api/functions/User";
import SetupApi from "../../../../common/api/functions/Setup";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {motion,AnimatePresence} from "framer-motion";
import {
    AccountInterface,
    JournalInterface,
    LayoutInterface,
    PaymentTermInterface
} from "../../../../interfaces/economic/CommonInterface";
import {SelectOptionInterface} from "../../../../interfaces/SelectOptionInterface";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {setLoading} from "../../../../app/rootReducer";
import ScheduleApi from "../../../../common/api/functions/Schedule";
import QButtonSwitch from "../../../../common/components/QButton/QButtonSwitch";
import {FormGroup, Input} from "reactstrap";

interface Props {
    formik: any
}

const Payment: React.FC<Props> = ({formik}) => {
    const {loading} = useSelector((state: RootState) => state.rootReducer);
    const dispatch = useDispatch();
    const [cashbooks, setCashbooks] = useState<JournalInterface[]>([]);
    const [accounts, setAccounts] = useState<AccountInterface[]>([]);
    const [matchingInvoice, setMatchingInvoice] = useState(false);

    useEffect(() => {

        if (loading) {
            SetupApi.getDataSetupPayment().then(async (response: ResponseInterface | any) => {
                let {cashbooks, accounts} = response?.data?.data;
                setCashbooks(cashbooks);
                setAccounts(accounts);
                dispatch(setLoading(false));
            })

        }
    }, [loading]);


    // console.log(formik.errors);
    return (
        <React.Fragment>
            <QSelect
                label={'Cashbook'}
                value={formik.values.economic_payment_cashbook_number}
                onChange={(option: SelectOptionInterface) => {
                    formik.setFieldValue('economic_payment_cashbook_number', option.value);
                }}
                options={cashbooks.map((a: JournalInterface) => ({label: a.name, value: a.journalNumber}))}
                error={formik.errors?.economic_payment_cashbook_number}
            />
            <QSelect
                label={'Bank account'}
                value={formik.values.economic_payment_bank_account_number}
                onChange={(option: SelectOptionInterface) => {
                    formik.setFieldValue('economic_payment_bank_account_number', option.value);
                }}
                options={accounts.map((a: AccountInterface) => ({
                    label: a.accountNumber + ' - ' + a.name,
                    value: a.accountNumber
                }))}
                error={formik.errors?.economic_payment_bank_account_number}
            />
            <QSelect
                label={'Fee account'}
                value={formik.values.economic_payment_fee_account_number}
                onChange={(option: SelectOptionInterface) => {
                    formik.setFieldValue('economic_payment_fee_account_number', option.value);
                }}
                options={accounts.map((a: AccountInterface) => ({
                    label: a.accountNumber + ' - ' + a.name,
                    value: a.accountNumber
                }))}
                error={formik.errors?.economic_payment_fee_account_number}
            />
            <QSelect
                label={'Difference currency account'}
                value={formik.values.economic_payment_difference_currency_account_number}
                onChange={(option: SelectOptionInterface) => {
                    formik.setFieldValue('economic_payment_difference_currency_account_number', option.value);
                }}
                options={accounts.map((a: AccountInterface) => ({
                    label: a.accountNumber + ' - ' + a.name,
                    value: a.accountNumber
                }))}
                error={formik.errors?.economic_payment_difference_currency_account_number}
            />

            <FormGroup switch>
                <label>Matching invoice?</label>
                <Input
                    type="switch"
                    // value={formik.values.economic_payment_matching_invoice}
                    checked={matchingInvoice}
                    onChange={(e) => {
                        formik.setFieldValue('economic_payment_matching_invoice', e.target.checked, false);
                        setMatchingInvoice((!matchingInvoice));
                    }}
                />
            </FormGroup>
            <AnimatePresence>

                {!formik.values.economic_payment_matching_invoice && (
                    <motion.div
                        initial={{opacity: 0, height: 0}}
                        animate={{opacity: 1, height: "auto"}}
                        exit={{opacity: 0, height: 0}}
                        transition={{duration: 0.3, ease: "easeInOut"}}
                    >
                        <QSelect
                            label={'Credit account'}
                            value={formik.values.economic_payment_credit_account_number}
                            onChange={(option: SelectOptionInterface) => {
                                formik.setFieldValue('economic_payment_credit_account_number', option.value);
                            }}
                            options={accounts.map((a: AccountInterface) => ({
                                label: a.accountNumber + ' - ' + a.name,
                                value: a.accountNumber
                            }))}
                            error={formik.errors?.economic_payment_credit_account_number}
                        /></motion.div>)}
            </AnimatePresence>


        </React.Fragment>
    )
}

export default Payment;
