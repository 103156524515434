import {Formik, useFormik} from "formik";
import * as Yup from "yup";
import ScheduleApi from "../../../../../common/api/functions/Schedule";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import {ResponseInterface} from "../../../../../interfaces/ResponseInterface";
import SetupApi from "../../../../../common/api/functions/Setup";
import {config} from "../../../../../config";
import {SelectOptionInterface} from "../../../../../interfaces/SelectOptionInterface";
import QSelect from "../../../../../common/components/QSelect";
import {ScheduleInterface} from "../../../../../interfaces/private/ScheduleInterface";
import QButton from "../../../../../common/components/QButton";

interface Props {
    loadSchedule: Function,
    setOpen: Function,
    allObjects: string[]
    schedules: ScheduleInterface[],
    FrequencyOptions:any[]
    StatusOptions:any[]
}

const BodyScheduleSetupComponent: React.FC<Props> = ({loadSchedule,setOpen,allObjects,schedules,FrequencyOptions,StatusOptions }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const availableObjects = allObjects.filter(
        (obj) => !schedules.some((schedule) => schedule.type === obj)
    );


    const formik = useFormik({
        initialValues: {
            type: '',
            frequency: '',
            status: 'active',
        },
        validationSchema: Yup.object({
            type: Yup.string().required('Please choose type.'),
            frequency: Yup.string().required('Frequency can not be blank.'),
        }),
        onSubmit: async (values) => {
            await setLoading(true);
            await ScheduleApi.create(values).then(async (response:ResponseInterface|any)=>{
                if (response?.status === config.STATUS_CODE.OK){
                    loadSchedule();
                    setOpen(false);
                }
            })
        }
    });

    return (
        <React.Fragment>
            {/* Object Type */}
            <Row>
                <Col>
                    <QSelect
                        label={'Type'}
                        value={formik.values.type}
                        onChange={(option:SelectOptionInterface) => {
                            formik.setFieldValue('type', option.value)
                        }}
                        options={availableObjects.map((obj)=>({label:obj.charAt(0).toUpperCase() + obj.slice(1),value:obj}))}
                        error={formik.errors?.type}
                    />
                    <QSelect
                        label={'Frequency'}
                        value={formik.values.frequency}
                        onChange={(option:SelectOptionInterface) => {
                            formik.setFieldValue('frequency', option.value)
                        }}
                        options={FrequencyOptions.map((a)=>({label:a.label,value:a.value}))}
                        error={formik.errors?.frequency}
                    />
                    <QSelect
                        label={'Status'}
                        value={formik.values.status}
                        onChange={(option:SelectOptionInterface) => {
                            formik.setFieldValue('status', option.value)
                        }}
                        options={StatusOptions.map((a)=>({label:a.label,value:a.value}))}
                        error={formik.errors?.status}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={'d-flex justify-content-end gap-2'}>
                        <QButton
                            type={'default'}
                            onClick={() => {
                                setOpen(false)
                            }}
                            content={t("common.button.cancel")}
                        />

                        <QButton
                            type={'primary'}
                            onClick={() => {
                                formik.handleSubmit()
                            }}
                            content={t("common.button.submit")}
                        />

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BodyScheduleSetupComponent
