import React, {useState} from "react";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import QButton from ".";

interface Props {
    onDelete: () => void;

}

const QButtonDelete: React.FC<Props> = ({onDelete}) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <>
            {/* Nút Delete */}
                <a className={""} href="#"><i className="las la-trash fs-20" onClick={toggle}/></a>

            {/* Modal Xác Nhận */}
                <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>Confirm Delete</ModalHeader>
                    <ModalBody>Are you sure you want to delete this item?</ModalBody>
                    <ModalFooter>
                        <QButton
                            type={'primary'}
                            onClick={() => {
                                onDelete();
                                toggle();
                            }}
                            content={'Delete'}
                        />
                        <QButton
                            type={'default'}
                            onClick={toggle}
                            content={'Cancel'}
                        />
                    </ModalFooter>
                </Modal>
        </>
    );
};

export default QButtonDelete;
