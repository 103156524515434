import React, {useCallback, useState, useEffect} from "react";
import QTextBox from "../../../common/components/QTextBox";
import {useFormik} from "formik";
import * as yup from "yup";
import {getValidation, SetupConfig} from "./config";
import QButton from "../../../common/components/QButton";
import AuthApi from "../../../common/api/functions/Auth";
import {config} from "../../../config";
import {useDispatch, useSelector} from "react-redux";
import {setLoading, setToast} from "../../../app/rootReducer";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {useHistory} from "react-router-dom";
import {RouteDefine} from "../../../routes/RouteDefine";
import SetupApi from "../../../common/api/functions/Setup";
import {SetupInterface} from "../../../interfaces/private/SetupInterface";
import QLoading from "../../../common/components/QLoading";
import {RootState} from "../../../app/store";
import {Progress} from "reactstrap";
import {QHelpers} from "../../../common/helpers";

interface Props {

}

const initialValues: any = {}

const Setup: React.FC<Props> = ({}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [step, setStep] = useState(1);
    const [noValidate, setNoValidate] = useState(false);
    const {location} = history;
    // const [loading, setLoading] = useDispatch(false);
    const {loading} = useSelector((state: RootState) => state.rootReducer);
    const [direction, setDirection] = useState(1); // 1: next, -1: previous

    const formik = useFormik({
        initialValues: {...initialValues, economic_payment_matching_invoice: false},
        enableReinitialize: true,
        validationSchema: getValidation(step, noValidate, yup), //getValidation(step,skipFinalStep, yup),
        onSubmit: async (values) => {
            dispatch(setLoading(true));
            if (step < 5) {
                setStep(step + 1)
            } else {
                await SetupApi.update(values as SetupInterface).then(async (response: ResponseInterface | any) => {
                    dispatch(setLoading(false));
                    if (!response?.data?.error) {
                        let accountData = JSON.parse(QHelpers.store.localStorageGetItem(config.LOCAL_STORE.ACCOUNT) ?? '{}');
                        accountData = {
                            ...accountData, user: {
                                ...accountData.user, // Giữ lại các dữ liệu khác của user (nếu có)
                                is_logged: 1
                            }
                        }
                        await localStorage.setItem(
                            config.LOCAL_STORE.ACCOUNT,
                            JSON.stringify(accountData),
                        )
                        setStep(6);
                    } else {
                        setStep(7);
                    }

                })
            }
        }
    })

    return (
        <React.Fragment>
            <QLoading loading={loading}/>
            <div className="mt-5 position-relative">
                <Progress style={{
                    height: '15px'
                }}
                          color="danger" value={100 / 6 * (step)}>{step}/6</Progress>
                <form method="">
                    {SetupConfig(step, direction, formik)}
                    {step < 6 ? (<a className={'float-end cl-orange-zp'} href={'#'} onClick={() => {

                        dispatch(setLoading(true));
                        if (step === 5) {
                            setNoValidate(true);
                            setTimeout(() => {
                                formik.handleSubmit();
                                setDirection(1);
                            }, 1000);
                        } else {
                            setStep(step + 1);
                            setDirection(1);
                        }

                    }}>Skip</a>) : ''
                    }
                    <div className="mt-5  d-flex gap-2">
                        {
                            step > 1 && step < 6 ? (
                                <QButton
                                    className={'btn bdr-10 fs-16 mt-3'}
                                    width={100}
                                    content={"Back"}
                                    size={''}
                                    onClick={() => {
                                        setDirection(-1);
                                        dispatch(setLoading(true));
                                        setStep(step - 1);
                                    }}
                                />
                            ) : null
                        }
                        {
                            step < 6 ? (
                                <QButton
                                    className={'btn bdr-10 fs-16 mt-3'}
                                    width={100}
                                    type={'primary'}
                                    content={step < 5 ? "Next" : "Submit"}
                                    size={''}
                                    onClick={() => {
                                        formik.handleSubmit();
                                        setDirection(1);
                                    }}/>
                            ) : null
                        }

                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Setup;
