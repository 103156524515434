import React from "react";
import Step1EconomicInfo from "../components/Step1EconomicInfo";
import Step2EconomicWebHook from "../components/Step2EconomicWebHook";
import Step3AccountShip from "../components/Step3AccountShip";
import StepSuccess from "../components/StepSuccess";
import StepError from "../components/StepError";
import Step2ConnectStripe from "../components/Step2ConnectStripe";
import Invoice from "../components/Invoice";
import Payment from "../components/Payment";
import Payout from "../components/Payout";
import Subscription from "../components/Subscription";
import Common from "../components/Common";
import {motion, AnimatePresence} from "framer-motion";

const STEP = {
    step1: 1,
    step2: 2,
    step3: 3,
    step4: 4,
}

const arrStepList = [
    {
        id: 1,
        label: ' Invoice',
        component: Invoice
    },
    {
        id: 2,
        label: ' Payment',
        component: Payment
    },
    {
        id: 3,
        label: ' Payout',
        component: Payout,
    },
    {
        id: 4,
        label: ' Subscription',
        component: Subscription,
    },
    {
        id: 5,
        label: ' Common',
        component: Common,
    },
    {
        id: 6,
        label: '',
        component: StepSuccess,
    },
    {
        id: 7,
        label: '',
        component: StepError,
    },
]

export const getValidation = (step: number,noValidate:boolean, yup: any) => {
    console.log('getValidation.noValidate',noValidate);
    if(noValidate) return yup.object().shape({});

    switch (step) {
        case 1:
            return yup.object().shape({
                economic_invoice_layout_number: yup.string().required("Please enter layout for invoice"),
                economic_invoice_payment_terms_number: yup.string().required("Please enter payment term for invoice"),
                economic_invoice_booked_with: yup.string().required("Please choose action book invoice"),
                economic_invoice_other_ref: yup.string().required("Please choose reference for invoice"),
                // economic_invoice_product_discount_number: yup.string().required("Please enter product for discount"),
            })
        case 2:
            return yup.object().shape({
                economic_payment_cashbook_number: yup.string().required("Please choose cashbook for payment"),
                economic_payment_bank_account_number: yup.string().required("Please choose bank account"),
                economic_payment_credit_account_number: yup.string().when(
                    "economic_payment_matching_invoice",
                    {
                        is: false, // Chỉ validate khi economic_payment_matching_invoice = false
                        then: yup.string().required("Please choose credit account"),
                        otherwise: yup.string().nullable(), // Không bắt buộc nếu matching_invoice = true
                    }
                ),
                economic_payment_fee_account_number: yup.string().required("Please choose fee account"),
                economic_payment_difference_currency_account_number: yup.string().required("Please choose difference currency account"),
            })
        case 3:
            return yup.object().shape({
                economic_payout_cashbook_number: yup.string().required("Please choose cashbook for payout"),
                economic_payout_bank_account_number: yup.string().required("Please choose bank account"),
                economic_payout_fee_account_number: yup.string().required("Please choose fee account"),
                economic_payout_difference_currency_account_number: yup.string().required("Please choose difference currency account"),
            })
        case 4:
            return yup.object().shape({
                economic_subscription_cashbook_number: yup.string().required("Please choose cashbook for payout"),
                economic_subscription_balance_account_number: yup.string().required("Please choose balance account"),
                economic_subscription_sales_account_number: yup.string().required("Please choose sale account"),
            })
        case 5:
            return yup.object().shape({
                economic_customer_group_number: yup.string().required("Please choose group for customer"),
                economic_customer_payment_terms_number: yup.string().required("Please choose payment term for customer"),
                economic_product_group_number: yup.string().required("Please choose group for product"),
            })
    }
}

export const SetupConfig = (step: number, direction: number, formik: any) => {

    const getStep: any = arrStepList.find((s: any) => s.id === step);

    const Component = getStep.component;
    return (
        <React.Fragment>
            <AnimatePresence mode="wait" custom={direction}>
                <motion.div
                    key={step}
                    initial={{x: direction * 200, opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    exit={{x: -direction * 200, opacity: 0}}
                    transition={{duration: 0.3, ease: "easeInOut"}}
                    className="step-content"
                >
                    {getStep?.label ? (
                        <h2 className="mb-4 mt-3 fs-32 cl-orange-zp fw-bold">
                            Setup :
                            <span className="fw-400">{getStep.label}</span>
                        </h2>
                    ) : null}
                    {
                        <Component formik={formik}/>
                    }
                </motion.div>
            </AnimatePresence>
        </React.Fragment>
    )
}
