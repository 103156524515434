import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {UseMainLayout} from "../../../context/LayoutContext";
import ScheduleApi from "../../../common/api/functions/Schedule";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import {config} from "../../../config";
import ServiceApi from "../../../common/api/functions/Service";
import moment from "moment";
import {RouteDefine} from "../../../routes/RouteDefine";
import {useHistory} from "react-router-dom";

interface ServiceState {
    name: string,
    status: string,
    last_run: string,
    count_message: Number
}

const  Dashboard =  () => {
    const history = useHistory();
    const {t, i18n} = useTranslation();
    UseMainLayout();

    const [serviceState,setServiceState] = useState<ServiceState[]>([]);


    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (loading) {
            loadService();
        }
    },[loading]);

    const loadService = async () => {
        setLoading(true);
        try{
            await ServiceApi.getAll().then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    setServiceState(
                        response.data.data || []
                    )
                }
            })
        }catch (e){

        }finally {
            await setLoading(false);
        }
    }


    let invoiceService = serviceState.find(s => s.name == 'invoice');
    let payoutService = serviceState.find(s => s.name == 'payout');
    let paymentService = serviceState.find(s => s.name == 'payment');
    let subscriptionService = serviceState.find(s => s.name == 'subscription');
    console.log('payoutService',payoutService);
    return (
        <React.Fragment>
            <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div className="page-title-name">{t("common.heading.services")}</div>
                </div>
            </div>

            <div className="col-lg-12">
                <div className="row mb-4">
                    <div className="col-md-3 mb-3">
                        <div className="service-card">
                            <h5>Invoice <a href={'#'} onClick={()=>     history.push(RouteDefine.invoice)}><i className="las la-external-link-alt fs-24 cl-br-ams ml-2"></i></a></h5>
                            <p className={'status-' + (invoiceService?.status === 'active'?'active':'inactive')}>{invoiceService?.status || 'n/a'}</p>
                            <p className="text-muted">
                                Last Run: {invoiceService?.last_run ? moment(invoiceService.last_run).format("DD/MM/YYYY HH:mm:ss") : 'n/a'}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="service-card">
                            <h5>Payment <a href={'#'} onClick={()=>     history.push(RouteDefine.payment)}><i className="las la-external-link-alt fs-24 cl-br-ams ml-2"></i></a></h5>
                            <p className={'status-' + (paymentService?.status === 'active'?'active':'inactive')}>{paymentService?.status || 'n/a'}</p>
                            <p className="text-muted">
                                Last Run: {paymentService?.last_run ? moment(paymentService.last_run).format("DD/MM/YYYY HH:mm:ss") : 'n/a'}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="service-card">
                            <h5>Payout <a href={'#'} onClick={()=>     history.push(RouteDefine.payout)}><i className="las la-external-link-alt fs-24 cl-br-ams ml-2"></i></a></h5>
                            <p className={'status-' + (payoutService?.status === 'active'?'active':'inactive')}>{payoutService?.status || 'n/a'}</p>
                            <p className="text-muted">
                                Last Run: {payoutService?.last_run ? moment(payoutService.last_run).format("DD/MM/YYYY HH:mm:ss") : 'n/a'}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <div className="service-card">
                            <h5>Subscription <a href={'#'} onClick={()=>     history.push(RouteDefine.subscription)}><i className="las la-external-link-alt fs-24 cl-br-ams ml-2"></i></a></h5>
                            <p className={'status-' + (subscriptionService?.status === 'active'?'active':'inactive')}>{subscriptionService?.status || 'n/a'}</p>
                            <p className="text-muted">
                                Last Run: {subscriptionService?.last_run ? moment(subscriptionService.last_run).format("DD/MM/YYYY HH:mm:ss") : 'n/a'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Dashboard);
