import { APIClient } from "../base";
import { SetupInterface } from "../../../interfaces/private/SetupInterface";

const SetupApi = {
    detail: async () => await APIClient.get('/setup', {}),
    getLayout: async (token = null ) => await APIClient.get('/setup/get-layouts', {'token' : token}),
    getLayouts: async (token = null ) => await APIClient.get('/setup/get-layouts-no-author', {'token' : token}),
    getDataSetupInvoice: async () => await APIClient.get('/setup/get-data-setup-invoice',{}),
    getAllDataSetup: async () => await APIClient.get('/setup/get-all-data-setup',{}),
    getDataSetupPayment: async () => await APIClient.get('/setup/get-data-setup-payment',{}),
    getDataSetupPayout: async () => await APIClient.get('/setup/get-data-setup-payout',{}),
    getDataSetupCommon: async () => await APIClient.get('/setup/get-data-setup-common',{}),
    searchProduct: async (s:string) => await APIClient.get('/setup/search-product',{s}),
    getWebhookInformation: async (token = null,accountNumber = null ) => await APIClient.get('/webhook-mobile-bay', {'token' : token, 'accountNumber': accountNumber}),
    // create: async (model: IntegrateInterface) => await APIClient.create('/integrate', model),
    update: async (model: SetupInterface) => await APIClient.update('/setup', model),
    // economicDetail: async () => await APIClient.get('/economic', {}),
    // economicUpdate: async (model:EconomicInterface) => await APIClient.update('/economic', model),

    checkSetup: async (action:string) => await APIClient.get('/setup/check-setup', {action}),
    finishWizardSetup: async () => await APIClient.post('/setup/finish-wizard-setup', {}),
    productGroup: async () => await APIClient.get('/setup/product-group',{}),
    updateProductGroup: async (productGroups:{[key:string]:string}) => await APIClient.post('/setup/update-product-groups', {product_groups:productGroups}),
}

export default SetupApi;
