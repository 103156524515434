import React, { useState } from "react";
import { motion } from "framer-motion";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";

interface Props {
    pagination: PaginationInterface;
    setPagination: (pagination: PaginationInterface) => void;
    setLoading?: (loading: boolean) => void;
}

const QPagination: React.FC<Props> = React.memo(({ pagination, setPagination, setLoading }) => {
    const totalPage = Math.ceil(pagination.total / (pagination.per_page > 0 ? pagination.per_page : 1));
    const [direction, setDirection] = useState(0); // 1: right, -1: left

    const variants = {
        enter: (direction: number) => ({
            x: direction > 0 ? 50 : -50, // Trượt từ phải hoặc trái
            opacity: 0,
        }),
        center: {
            x: 0,
            opacity: 1,
        },
        exit: (direction: number) => ({
            x: direction < 0 ? 50 : -50, // Trượt ra phải hoặc trái
            opacity: 0,
        }),
    };

    const handlePageChange = (newPage: number) => {
        if (newPage !== pagination.page) {
            setDirection(newPage > pagination.page ? 1 : -1);
            setPagination({ ...pagination, page: newPage });
            if (setLoading) setLoading(true);
        }
    };

    const renderPage = () => {
        const maxPagesToShow = 5;
        const startPage = Math.max(1, pagination.page - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPage, startPage + maxPagesToShow - 1);
        const arrPage: JSX.Element[] = [];

        for (let i = startPage; i <= endPage; i++) {
            arrPage.push(
                <li className={`page-item cursor-pointer ${pagination.page === i ? "active" : ""}`} key={i}>
                    <a className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
                </li>
            );
        }
        return arrPage;
    };

    return (
        <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
            <div className="col-5 text-left">
                <div className="text-muted fs-12" style={{ display: "flex" }}>
                    <div>
                        Showing <span className="fw-semibold">
              {(pagination.page - 1) * pagination.per_page + 1}
                        <span> to </span>
                        {pagination.per_page * pagination.page > pagination.total ? pagination.total : pagination.per_page * pagination.page}
            </span> of <span className="fw-semibold">{pagination.total}</span> entries
                    </div>
                </div>
            </div>
            <div className="col-7">
                <motion.ul
                    className="pagination pagination-separated pagination-sm justify-content-center justify-content-sm-start mb-0 float-end"
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: 0.5 }}
                    key={pagination.page} // Key để trigger animation khi trang thay đổi
                >
                    <li
                        className={`page-item ${pagination.page > 1 ? "cursor-pointer" : "disabled"}`}
                        onClick={() => handlePageChange(pagination.page - 1)}
                    >
                        <a className="page-link"><i className="arrow-left"></i></a>
                    </li>
                    {renderPage()}
                    <li
                        className={`page-item ${pagination.page < totalPage ? "cursor-pointer" : "disabled"}`}
                        onClick={() => handlePageChange(pagination.page + 1)}
                    >
                        <a className="page-link"><i className="arrow-right"></i></a>
                    </li>
                </motion.ul>
            </div>
        </div>
    );
});

export default QPagination;
