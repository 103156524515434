import React, {useEffect, useState} from "react";
import ScheduleApi from "../../../../common/api/functions/Schedule";
import {PaginationInterface, structPagination} from "../../../../interfaces/PaginationInterface";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import {config} from "../../../../config";
import QLoading from "../../../../common/components/QLoading";
import QHeaderTable from "../../../../common/components/Genneral/QHeaderTable";
import QPagination from "../../../../common/components/QPaging";
import {t} from "i18next";
import {ScheduleConfig} from "./config";
import QButton from "../../../../common/components/QButton";
import QModal from "../../../../common/components/QModal";
import BodyScheduleSetupComponent from "./components/BodyScheduleSetupComponent";
import {ScheduleInterface} from "../../../../interfaces/private/ScheduleInterface";
import QButtonDelete from "../../../../common/components/QButton/QButtonDelete";
import {UseSecondLayout} from "../../../../context/LayoutContext";
import QButtonSwitch from "../../../../common/components/QButton/QButtonSwitch";




const Schedule: React.FC = () => {
    UseSecondLayout();
    const FrequencyOptions = [
        // {value:"* * * * *",label:"Every Minute"},
        {value:"0 * * * *",label:"Every Hour"},
        {value:"0 0 * * *",label:"Every Day (Midnight)"},
        { value: "0 6,18 * * *", label: "Every Day (6 AM & 6 PM)" }
        // {value:"0 0 * * 0",label:"Every Week (Sunday Midnight)"},
        // {value:"0 0 1 * *",label:"EveryMonth (1st Day)"},
    ]

    const StatusOptions = [
        {value:"active",label:"Active"},
        {value:"inactive",label:"In-active"},
    ];

    const [loading, setLoading] = useState(true);

    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
    })

    const [createNewClick, setCreateNewClick] = useState(false);
    const [updateNewClick, setUpdateNewClick] = useState(false);

    const [schedules, setSchedules] = useState<ScheduleInterface[]>([]);

    const allObjects = ["payment", "payout", "subscription", "invoice"];




    useEffect(() => {
        if (loading) {
            loadSchedule();
        }
    },[loading]);

    const loadSchedule = async () => {
        setLoading(true);
        try{
            await ScheduleApi.getAll(pagination).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    setSchedules(
                        response.data.data.data || []
                    )
                    setPagination({
                        ...pagination,
                        page: response.data.data.current_page,
                        total: response.data.data.total,
                        per_page: response.data.data.per_page
                    })
                }
            })
        }catch (e){

        }finally {
            await setLoading(false);
        }
    }

    const deleteSchedule = async (id: number) => {
        setLoading(true);
        try {
            await ScheduleApi.delete(id).then(async (response: ResponseInterface | any) => {
                console.log(response);
                if (response.status === config.STATUS_CODE.OK) {
                    await loadSchedule();
                }
            })
        } catch (e) {
            console.log(e);
        }finally {
            await setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={'Schedule'}
                bodyContent={(
                    <BodyScheduleSetupComponent
                        setOpen={setCreateNewClick}
                        allObjects={allObjects}
                        schedules={schedules}
                        FrequencyOptions={FrequencyOptions}
                        StatusOptions={StatusOptions}
                        loadSchedule={loadSchedule}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading}/>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">Schedule Management</div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="fw-bold fs-24 mb-2">Schedule list</h2>
                            </div>
                            <div className="col-md-4 text-end">
                                <QButton
                                    type={'primary'}
                                    onClick={() => {
                                        setCreateNewClick(true)
                                    }}
                                    content={t('New')}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="mb-3">
                                <QLoading loading={loading}/>
                                <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                    <QHeaderTable
                                        paging={pagination}
                                        setPaging={async (p: any) => {
                                            await setPagination(p);
                                            await setLoading(true);
                                        }}
                                        headers={ScheduleConfig.headers(t)}
                                        funcChecked={() => {
                                        }}
                                    />
                                    <tbody className="list form-check-all">
                                    {schedules?.map((schedule: ScheduleInterface, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td> {schedule.type.charAt(0).toUpperCase() + schedule.type.slice(1)}</td>
                                                <td>  {FrequencyOptions.find(a => a.value === schedule.frequency)?.label}</td>
                                                <td>   <QButtonSwitch
                                                    item={schedule}
                                                    getStatus={(schedule) => schedule.status === "active"}
                                                    onToggle={async (id, newStatus) => {
                                                        await ScheduleApi.update(schedule.id,{'status':newStatus?'active':'inactive'});
                                                    }}
                                                /></td>
                                                <td>

                                                    <QButtonDelete  onDelete={() => {
                                                        deleteSchedule(schedule.id);
                                                    }}/>

                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                            <QPagination
                                pagination={pagination}
                                setPagination={setPagination}
                                setLoading={setLoading}
                            />
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>

    );
};

export default Schedule;
