import {FormGroup, Input} from "reactstrap";
import React from "react";

interface SwitchButtonProps<T> {
    item: T;
    getStatus: (item: T) => boolean; // Callback để lấy trạng thái
    onToggle: (id: string | number, newStatus: boolean) => Promise<void>;
}

const QButtonSwitch = <T,>({ item, getStatus, onToggle }: SwitchButtonProps<T>) => {
    const [status, setStatus] = React.useState(getStatus(item));

    async function toggleSwitch(id: string | number) {
        const newStatus = !status;
        setStatus(newStatus);
        try {
            await onToggle(id, newStatus);
        } catch (err) {
            setStatus(!newStatus);
        }
    }

    return (
        <FormGroup switch>
            <Input
                type="switch"
                checked={status}
                onChange={() => toggleSwitch((item as any).id)} // Giả định item có id
            />
        </FormGroup>
    );
};

export default QButtonSwitch;
