import React, { useEffect, useState } from "react";
import { ResponseInterface } from "../../../interfaces/ResponseInterface";
import { PaginationInterface, structPagination } from "../../../interfaces/PaginationInterface";
import QPagination from "../../../common/components/QPaging";
import QLoading from "../../../common/components/QLoading";
import { useHistory} from "react-router-dom";
import { RouteDefine } from "../../../routes/RouteDefine";
import { UseMainLayout } from "../../../context/LayoutContext";
import QSearch from "../../../common/components/Genneral/QSearch";
import { config } from "../../../config";
import QHeaderTable from "../../../common/components/Genneral/QHeaderTable";
import QSelectPerPage from "../../../common/components/Genneral/QSelectPerPage";
import UserApi from "../../../common/api/functions/User";
import { UserInterface } from "../../../interfaces/private/UserInterface";
import { UserConfig } from "./config";
import QIconSwitch from "../../../common/components/QIcon/QIconSwitch";
import {QHelpers} from "../../../common/helpers";
import moment from "moment/moment";
import {Button, FormGroup, Input, Label} from "reactstrap";
import {InputType} from "reactstrap/types/lib/Input";




const SwitchButton = ({ user }: { user: UserInterface }) => {
    const [status, setStatus] = useState(user.status);


    async function toggleSwitch(id: any) {
        const newStatus = !status;
        setStatus(newStatus);
        try {
            let response: ResponseInterface | any = await UserApi.toggleStatus(id)
            if (response.status === config.STATUS_CODE.OK) {
                // setStatus(response.data.data.status)
            }
        } catch (err) {
            setStatus(!newStatus);
        }
    }
    return (
        <FormGroup switch>
            <Input
                type="switch"
                checked={status}
                onChange={() => toggleSwitch(user.id)}
            />
        </FormGroup>
    );
}

const Admin = () => {
    const history = useHistory();
    const [userChecked, setUserChecked] = useState<any>({
        isCheckAll: false,
        arrUser: [],
    });

    const [users, setUsers] = useState<Array<UserInterface>>([]);
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
        key: 'email',
        sort: 'desc',
    })
    const [loading, setLoading] = useState(true);

    UseMainLayout();

    useEffect(() => {
        if (loading) {
            loadUser().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])
    const loadUser = async () => {
        try {
            let response: ResponseInterface|any = await UserApi.getAll(pagination)
            if (response.status === config.STATUS_CODE.OK) {
                response = response.data.data;
                await setUsers(
                    response?.data || []
                )
                await setPagination({
                    ...pagination,
                    page: response.current_page,
                    total: response.total,
                    per_page: response.per_page
                })
            }else if(response.status === config.STATUS_CODE.FORBIDDEN){
                history.push(RouteDefine.forbidden)
            }

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }



    return (
        <React.Fragment>
            <div className="col-md-12">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">Admin</div>
                            <div className="page-title-right">
                                <div className="d-flex">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <QSearch
                                    onChange={async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                                <QSelectPerPage pagination={pagination} setPagination={setPagination} setLoading={setLoading} />
                            </div>
                            <QLoading loading={loading} />
                            <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                <QHeaderTable
                                    paging={pagination}
                                    setPaging={async (p: any) => {
                                        await setPagination(p);
                                        await setLoading(true);
                                    }}
                                    headers={UserConfig.headers}
                                    funcChecked={() => {

                                        let arrUser = [];
                                        if (!userChecked.isCheckAll) {
                                            arrUser = users?.map((u: UserInterface) => u.email);
                                        }

                                        setUserChecked({
                                            ...userChecked,
                                            isCheckAll: !userChecked.isCheckAll,
                                            arrUser: arrUser,
                                        })
                                    }}
                                />
                                <tbody className="list form-check-all">
                                    {users?.map((user: UserInterface, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{user.id}</td>
                                                <td>{user.email}</td>
                                                <td>{user.name}</td>
                                                <td>{user.company_name}</td>

                                                <td>
                                                    {(user.address ? (user.address + ',') : '')
                                                        + (user.zip ? (" " + user.zip) : '')
                                                        + (user.city ? (" " + user.city) : '')
                                                        + (user.country ? (" " + user.country) : '')}
                                                </td>
                                                <td className="">{user.cvr_no} </td>
                                                <td className="">
                                                    {user.phone}
                                                </td>
                                                <td>{(moment(user.created_at)).isValid() && moment(user.created_at).format('DD.MM.YY hh:mm')}</td>
                                                <td>{<SwitchButton user={user}/>}</td>
                                                <td>
                                                    <div
                                                        className={'d-flex justify-content-center cursor-pointer'}
                                                        onClick={ async () => {
                                                            let response = await UserApi.switchUser(user.id);
                                                            if(response?.data?.data?.access_token){
                                                                await QHelpers.store.sessionStorageSetItem(
                                                                    config.LOCAL_STORE.ACCOUNT_SWITCH,
                                                                    response.data.data.access_token
                                                                )

                                                                await history.push(RouteDefine.invoice);
                                                            }
                                                        }}
                                                    >
                                                        <QIconSwitch />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default React.memo(Admin);
