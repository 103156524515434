import { APIClient } from "../base";
import { PaginationInterface } from "../../../interfaces/PaginationInterface";

const ScheduleApi = {
    getAll: async (pagination: PaginationInterface) => await APIClient.get('/schedules', pagination),
    getDetail: async (id: number | string | any) => await APIClient.get('/schedules/' + id, {}),
    create: async (object: any) => await APIClient.create('/schedules', object),
    update: async (id: number,object: any) => await APIClient.update('/schedules/' + id, object),
    delete: async (id: number) => await APIClient.delete('/schedules/' + id,{})
}

export default ScheduleApi;
