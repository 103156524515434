import React, {useCallback, useEffect, useState} from "react";
import {ResponseInterface} from "../../../../interfaces/ResponseInterface";
import OrderApi from "../../../../common/api/functions/Order";
import {LogInterface, structLog} from "../../../../interfaces/private/LogInterface";
import {PaginationInterface, structPagination} from "../../../../interfaces/PaginationInterface";
import QPagination from "../../../../common/components/QPaging";
import QLoading from "../../../../common/components/QLoading";
import {Link} from "react-router-dom";
import {RouteDefine} from "../../../../routes/RouteDefine";
import {UseMainLayout} from "../../../../context/LayoutContext";
import QSearch from "../../../../common/components/Genneral/QSearch";
import moment from "moment";
import {config} from "../../../../config";
import QHeaderTable from "../../../../common/components/Genneral/QHeaderTable";
import {OrderConfig} from "./config";
import QSelectPerPage from "../../../../common/components/Genneral/QSelectPerPage";
import {useTranslation} from "react-i18next";
import LogApi from "../../../../common/api/functions/Log";
import IntegrateApi from "../../../../common/api/functions/Integrate";
import QModal from "../../../../common/components/QModal";
import BodyModalIntegrationComponent from "../components/BodyModalIntegrationComponent";
import BodyModalLogDetailComponent from "../components/BodyModalLogDetailComponent";
import SetupApi from "../../../../common/api/functions/Setup";
import BodyModalMessageSetupComponent from "../components/BodyModalMessageSetupComponent";

const Invoice = () => {
    const {t, i18n} = useTranslation();

    const [logChecked, setLogChecked] = useState<any>({
        isCheckAll: false,
        arrOrder: [],
    });
    const [openMessageSetup, setOpenMessageSetup] = useState(false);
    const [openModalIntegration, setOpenModalIntegration] = useState(false);
    const [openModalLogDetail, setOpenModalLogDetail] = useState(false);
    const [progress,setProgress] = useState(0);
    const [logs, setLogs] = useState<Array<LogInterface>>([]);
    const [log, setLog] = useState<LogInterface>(structLog);
    const [pagination, setPagination] = useState<PaginationInterface | any>({
        ...structPagination,
    })
    const [loading, setLoading] = useState(true);
    const [loadingLogDetail, setLoadingLogDetail] = useState(false);
    const [loadingSetup, setLoadingSetup] = useState(false);
    UseMainLayout();

    useEffect(() => {
        if (loading) {
            loadLog().then(async () => {
                await setLoading(false);
            });
        }
    }, [loading])

    const loadLog = async () => {
        try {
            await LogApi.getAll(pagination).then(async (response: ResponseInterface | any) => {
                if (response.status === config.STATUS_CODE.OK) {
                    response = response.data.data;
                    setLogs(
                        response?.data || []
                    )
                    setPagination({
                        ...pagination,
                        page: response.current_page,
                        total: response.total,
                        per_page: response.per_page
                    })
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }

    const callIntegration = async(params:any) => {
        console.log(params);
        setProgress(80);
        return IntegrateApi.syncInvoice(params).then(async (response: ResponseInterface | any) => {
            setLoading(true);
            setProgress(100);

      });
    };

    const handleViewLogDetail = (_id: number) => {
        setLoadingLogDetail(true);
        setOpenModalLogDetail(true);
        LogApi.getDetail(_id).then(async (response:ResponseInterface | any)=>{
            if(response?.data?.data){
                setLog(response?.data?.data);
                setTimeout(()=>{
                    setLoadingLogDetail(false);
                },3000);
            }
        })
    };

    const checkSetup = () => {
        setLoadingSetup(true);
        SetupApi.checkSetup('invoice').then(async (response:ResponseInterface | any)=>{
            if(response?.data?.data){
                let {invoice,common} = response?.data?.data;
                /*if(!invoice){
                    await SetupApi.getDataSetupInvoice().then(async (response: ResponseInterface | any)=>{
                        if(response?.data?.data){
                            setDataSetupInvoice(response?.data?.data);
                            setOpenModalSetupInvoice(true);
                        }
                    })
                }else if(!common){
                    await SetupApi.getDataSetupCommon().then(async (response: ResponseInterface | any)=>{
                        if(response?.data?.data){
                            setDataSetupCommon(response?.data?.data);
                            setOpenModalSetupCommon(true);
                        }
                    })
                }*/

                if(invoice && common){
                    setOpenModalIntegration(true);
                }else{
                    setOpenMessageSetup(true);
                }

            }

            setLoadingSetup(false);
        });
    }

    return (
        <React.Fragment>
            {/*<QModal
                open={openModalSetupInvoice}
                setOpen={setOpenModalSetupInvoice}
                headerContent={"Invoice"}
                bodyContent={(
                    <BodyInvoiceSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupInvoice}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoadingSetup}
                        allDataSetup={dataSetupInvoice}
                    />
                )}
            />

            <QModal
                open={openModalSetupCommon}
                setOpen={setOpenModalSetupCommon}
                headerContent={"Common"}
                bodyContent={(
                    <BodyCommonSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupCommon}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoading}
                        allDataSetup={dataSetupCommon}
                    />
                )}
            />*/}

            <QModal
                open={openMessageSetup}
                setOpen={setOpenMessageSetup}
                headerContent={'Notice!'}
                bodyContent={(
                    <BodyModalMessageSetupComponent
                    />
                )}
                // onClosed={()=>setProgress(0)}
            />

            <QModal
                open={openModalIntegration}
                setOpen={setOpenModalIntegration}
                headerContent={t("common.modal.integration")}
                bodyContent={(
                    <BodyModalIntegrationComponent
                        setOpen={setOpenModalIntegration}
                        open={openModalIntegration}
                        callIntegration={callIntegration}
                        progress={progress}
                        showForceImport={true}
                    />
                )}
                onClosed={()=>setProgress(0)}
            />
            <QModal
                size={'xl'}
                open={openModalLogDetail}
                setOpen={setOpenModalLogDetail}
                headerContent={'Detail'}
                onClosed={()=>  setLog(structLog)}
                bodyContent={(
                    <BodyModalLogDetailComponent
                        setOpen={setOpenModalLogDetail}
                        open={openModalIntegration}
                        setLoading={setLoadingLogDetail}
                        loading={loadingLogDetail}
                        log={log}
                    />
                )}

            />
            <div className="col-md-12">
                <div className="row ">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <div className="page-title-name">{t("common.heading.invoices")}</div>
                            <div className="page-title-right">
                                <div className="d-flex">
                                    <a href="#" className="btn btn-sm  bg-orange-zp ml-3" onClick={() => checkSetup()}><span><i
                                        className="lab la-telegram mr-1"></i> {t("common.heading.manualIntegration")}</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-3">
                            <div className="card-header align-items-center d-flex">
                                <div className="mb-0 flex-grow-1">
                                    <ul className="nav nav-tabs nav-tabs-custom nav-success bd-none" role="tablist">
                                    </ul>
                                </div>
                                <QSearch
                                    onChange={async (value: string) => {
                                        await setPagination({
                                            ...pagination,
                                            search: value,
                                        })
                                        await setLoading(true);
                                    }}
                                />
                                <QSelectPerPage pagination={pagination} setPagination={setPagination}
                                                setLoading={setLoading}/>
                            </div>
                            <QLoading loading={loading || loadingLogDetail ||loadingSetup}/>
                            <table className="table align-middle table-nowrap mb-0" id="customerTable">
                                <QHeaderTable
                                    paging={pagination}
                                    setPaging={async (p: any) => {
                                        await setPagination(p);
                                        await setLoading(true);
                                    }}
                                    headers={OrderConfig.headers(t)}
                                    funcChecked={() => {

                                        let arrOrder:any = [];
                                        if (!logChecked.isCheckAll) {
                                            arrOrder = logs?.map((o: LogInterface) => o._id);
                                        }

                                        setLogChecked({
                                            ...logChecked,
                                            isCheckAll: !logChecked.isCheckAll,
                                            arrOrder: arrOrder,
                                        })
                                    }}
                                />
                                <tbody className="list form-check-all">
                                {logs?.map((log: LogInterface, index: number) => {
                                    let checkedLogIndex: any | LogInterface = logChecked.arrOrder?.findIndex((o: number) => o === log._id);
                                    return (
                                        <tr key={index}>
                                            <th scope="row">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input chk_child"
                                                        type="checkbox"
                                                        name="chk_child"
                                                        checked={checkedLogIndex > -1}
                                                        defaultValue="option1"
                                                        onClick={(event: any) => {
                                                            if (checkedLogIndex > -1) {
                                                                let clone = { ...logChecked };
                                                                clone.arrOrder.splice(checkedLogIndex, 1);
                                                                setLogChecked(clone)
                                                            } else {
                                                                let clone = { ...logChecked };
                                                                clone.arrOrder.push(log._id);
                                                                setLogChecked(clone)

                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            <td> {log.ref_id}</td>
                                            <td> {log.status}</td>
                                            <td>  {log.message.substring(0,100)}</td>
                                            <td>{(moment(log.created_at)).isValid() && moment(log.created_at).format('hh:mm DD.MM.YY')}</td>
                                            <td className="text-end">
                                                 <a href={'#'} onClick={(e)=>{e.preventDefault();handleViewLogDetail(log._id);}}>
                                                        <i className="las la-chevron-right fs-16 cl-br-ams ml-2" />
                                                    </a>

                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <QPagination
                            pagination={pagination}
                            setPagination={setPagination}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}
export default React.memo(Invoice);
