import React, {useEffect, useState} from "react";
import {Col, Input, Label, Progress, Row} from "reactstrap";
import QButton from "../../../../common/components/QButton";
import {t} from "i18next";
import {setLoading} from "../../../../app/rootReducer";

interface Props {

}

const BodyModalMessageSetupComponent: React.FC<Props> = ({}) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <div className="mb-3">
                        <p>Please complete the following setup before proceeding with the integration:</p>
                        <ul>
                            <li><a href="/economic?setup=invoice" target="_blank">Setup for invoices</a></li>
                            <li><a href="/economic?setup=common" target="_blank">Setup for general settings</a></li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default BodyModalMessageSetupComponent
