import React, {useEffect, useState} from "react";
import {ResponseInterface} from "../../../interfaces/ResponseInterface";
import QLoading from "../../../common/components/QLoading";
import {UseSecondLayout} from "../../../context/LayoutContext";
import QModal from "../../../common/components/QModal";
import SetupApi from "../../../common/api/functions/Setup";
import BodyEconomicComponent from "./components/BodyEconomicComponent";
import {SetupInterface} from "../../../interfaces/private/SetupInterface";
import {config} from "../../../config";
import {QHelpers} from "../../../common/helpers";
// @ts-ignore
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styles from "../../public/Setup/index.module.css";
import QIconCheck from "../../../common/components/QIcon/QIconCheck";
import {useTranslation} from "react-i18next";
import BodyInvoiceSetupComponent from "./components/BodyInvoiceSetupComponent";
import {
    AccountInterface, CustomerGroupInterface,
    JournalInterface,
    LayoutInterface,
    PaymentTermInterface, ProductGroupInterface
} from "../../../interfaces/economic/CommonInterface";
import BodyPaymentSetupComponent from "./components/BodyPaymentSetupComponent";
import BodyPayoutSetupComponent from "./components/BodyPayoutSetupComponent";
import BodySubscriptionSetupComponent from "./components/BodySubscriptionSetupComponent";
import product from "../../../common/api/functions/Product";
import BodyCommonSetupComponent from "./components/BodyCommonSetupComponent";


export function Economic() {
    interface Layout {
        layoutNumber: number,
        name: string
    }


    const {t} = useTranslation();
    const [setup, setSetup] = useState<SetupInterface>({});
    const [loading, setLoading] = useState(true);
    const [createNewClick, setCreateNewClick] = useState(false);
    const [openModalSetupInvoice, setOpenModalSetupInvoice] = useState(false);
    const [openModalSetupPayment, setOpenModalSetupPayment] = useState(false);
    const [openModalSetupPayout, setOpenModalSetupPayout] = useState(false);
    const [openModalSetupSubscription, setOpenModalSetupSubscription] = useState(false);
    const [openModalSetupCommon, setOpenModalSetupCommon] = useState(false);

    const [layouts, setLayouts] = useState<LayoutInterface[]>([]);
    const [paymentTerms, setPaymentTerms] = useState<PaymentTermInterface[]>([]);
    const [bookedWiths, setBookedWiths] = useState<any>({});
    const [otherRef, setOtherRef] = useState<any>({});
    const [cashbooks, setCashbooks] = useState<JournalInterface[]>([]);
    const [accounts, setAccounts] = useState<AccountInterface[]>([]);
    const [customerGroups, setCustomerGroups] = useState<CustomerGroupInterface[]>([]);
    const [productGroups, setProductGroups] = useState<ProductGroupInterface[]>([]);
    const [allDataSetup, setAllDataSetup] = useState<any>({});

    let objectParams = QHelpers.object.convertParamUrlToObject(window.location.search);

    UseSecondLayout();

    const [isModalInvoiceShown, setIsModalInvoiceShown] = useState(false);
    const [isModalCommonShown, setIsModalCommonShown] = useState(false);

/*    useEffect(() => {
        if(objectParams?.setup == 'invoice'){
            // setIsModalInvoiceShown(true);
            setOpenModalSetupInvoice(true);
        }

    });*/

    useEffect(() => {
        if (loading) {
            Promise.all([
                load().then(async () => {
                }),
                SetupApi.getAllDataSetup().then(async (response: ResponseInterface | any) => {
                    setAllDataSetup(response?.data?.data);
                    let {
                        layouts,
                        paymentTerms,
                        bookedWiths,
                        otherRef,
                        cashbooks,
                        accounts,
                        customerGroups,
                        productGroups
                    } = response?.data?.data;
                    setLayouts(layouts);
                    setPaymentTerms(paymentTerms);
                    setBookedWiths(bookedWiths);
                    setOtherRef(otherRef);
                    setCashbooks(cashbooks);
                    setAccounts(accounts);
                    setCustomerGroups(customerGroups);
                    setProductGroups(productGroups);
                }),
            ]).then(async () => {
                await setLoading(false);

                if(objectParams?.setup == 'invoice' && !isModalInvoiceShown){
                    setIsModalInvoiceShown(true);
                    setOpenModalSetupInvoice(true);
                }else if(objectParams?.setup == 'common' && !isModalCommonShown){
                    setIsModalCommonShown(true);
                    setOpenModalSetupCommon(true);
                }
            });
        }
    }, [loading])

    const load = async () => {
        try {
            await SetupApi.detail().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    setSetup(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
            console.error(err);
        } finally {
            await setLoading(false);
        }
    }

    /*const loadLayout = async () => {
        try {
            await SetupApi.getLayout().then(async (response: ResponseInterface | any) => {
                if (response?.status === config.STATUS_CODE.OK) {
                    console.log(response?.data.data);

                    setLayouts(
                        response?.data?.data || {}
                    )
                }
            })

        } catch (err) {
        }
        if (loading) {
            await setLoading(false);
        }
    }*/

    const getDisplayFromSelection = (items: any[],
                                     key: string,
                                     value: string | number,
                                     displayKey: string,
                                     additionalKey?: string): string => {
        const item = items.find((i: any) => i[key] == value);
        if (item) {
            const displayValue = item[displayKey];
            const additionalValue = additionalKey ? item[additionalKey] : null;
            return additionalValue ? `${displayValue} - ${additionalValue}` : displayValue;
        }
        return '';
    };
    return (
        <React.Fragment>
            <QModal
                open={createNewClick}
                setOpen={setCreateNewClick}
                headerContent={t("economic.title")}
                bodyContent={(
                    <BodyEconomicComponent
                        model={setup}
                        setOpen={setCreateNewClick}
                        setSetup={setSetup}
                        setLoadingFunc={setLoading}
                    />
                )}
            />
            <QModal
                open={openModalSetupInvoice}
                setOpen={setOpenModalSetupInvoice}
                headerContent={"Invoice"}
                bodyContent={(
                    <BodyInvoiceSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupInvoice}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoading}
                        allDataSetup={allDataSetup}
                    />
                )}
            />
            <QModal
                open={openModalSetupPayment}
                setOpen={setOpenModalSetupPayment}
                headerContent={"Payment"}
                bodyContent={(
                    <BodyPaymentSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupPayment}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoading}
                        allDataSetup={allDataSetup}
                    />
                )}
            />
            <QModal
                open={openModalSetupPayout}
                setOpen={setOpenModalSetupPayout}
                headerContent={"Payout"}
                bodyContent={(
                    <BodyPayoutSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupPayout}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoading}
                        allDataSetup={allDataSetup}
                    />
                )}
            />
            <QModal
                open={openModalSetupSubscription}
                setOpen={setOpenModalSetupSubscription}
                headerContent={"Subscription"}
                bodyContent={(
                    <BodySubscriptionSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupSubscription}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoading}
                        allDataSetup={allDataSetup}
                    />
                )}
            />
            <QModal
                open={openModalSetupCommon}
                setOpen={setOpenModalSetupCommon}
                headerContent={"Common"}
                bodyContent={(
                    <BodyCommonSetupComponent
                        model={setup}
                        setOpen={setOpenModalSetupCommon}
                        setSetup={setSetup}
                        setup={setup}
                        setLoadingFunc={setLoading}
                        allDataSetup={allDataSetup}
                    />
                )}
            />
            <div className="col-md-10 relative">
                <QLoading loading={loading}/>
                <div className="row ">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="page-title-name">{t("economic.title")}</div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">{t("economic.token")}</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setCreateNewClick(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <p className="mb-1">{setup.economic_token}</p>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-12 mb-2"><a href={QHelpers.string.generateUrlEconomic()}>{t("economic.generateNewToke")}</a></div>
                                </div> */}

                            </div>
                            <br/>

                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Invoice</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setOpenModalSetupInvoice(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><b
                                        className={'text-danger'}>Layout:</b> {layouts.find((l: LayoutInterface) => l.layoutNumber == setup.economic_invoice_layout_number)?.name}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Payment
                                        terms:</b> {paymentTerms.find((l: PaymentTermInterface) => l.paymentTermsNumber == setup.economic_invoice_payment_terms_number)?.name || ''}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Booked
                                        with:</b> {bookedWiths[setup.economic_invoice_booked_with] ?? ''}</div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Other
                                        ref:</b> {otherRef[setup.economic_invoice_other_ref] || ''}</div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Product
                                        discount:</b> {setup.economic_invoice_product_discount_number}</div>
                                </div>

                            </div>
                            <br/>
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Payment</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setOpenModalSetupPayment(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><b
                                        className={'text-danger'}>Cashbook:</b> {cashbooks.find((l: JournalInterface) => l.journalNumber == setup.economic_payment_cashbook_number)?.name}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Bank
                                        account: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_payment_bank_account_number,'accountNumber','name')}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Fee
                                        account: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_payment_fee_account_number,'accountNumber','name')}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Difference account
                                        currency: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_payment_difference_currency_account_number,'accountNumber','name')}
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Payout</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setOpenModalSetupPayout(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><b
                                        className={'text-danger'}>Cashbook:</b> {cashbooks.find((l: JournalInterface) => l.journalNumber == setup.economic_payout_cashbook_number)?.name}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Bank
                                        account: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_payout_bank_account_number,'accountNumber','name')}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Fee
                                        account: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_payout_fee_account_number,'accountNumber','name')}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Difference account
                                        currency:</b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_payout_difference_currency_account_number,'accountNumber','name')}
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Subscription</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setOpenModalSetupSubscription(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><b
                                        className={'text-danger'}>Cashbook:</b> {cashbooks.find((l: JournalInterface) => l.journalNumber == setup.economic_subscription_cashbook_number)?.name}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Balance
                                        account: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_subscription_balance_account_number,'accountNumber','name')}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Sales
                                        account: </b>
                                        {getDisplayFromSelection(accounts,'accountNumber',setup.economic_subscription_sales_account_number,'accountNumber','name')}
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <div className="business-detail bg-orange-bland-zp bdr-20 pd-25">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2 className="fw-bold fs-24 mb-2">Common</h2>
                                    </div>
                                    <div className="col-md-4 text-end">
                                        <a className={""} href="#" data-bs-toggle="modal"
                                           data-bs-target="#showModalBusinessDetail"><i
                                            className="las la-pen fs-24 cl-br-ams ml-2"
                                            onClick={() => {
                                                setOpenModalSetupCommon(true);
                                            }}
                                        /></a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Customer
                                        group:</b> {customerGroups.find((l: CustomerGroupInterface) => l.customerGroupNumber == setup.economic_customer_group_number)?.name}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Customer payment
                                        terms:</b> {paymentTerms.find((l: PaymentTermInterface) => l.paymentTermsNumber == setup.economic_customer_payment_terms_number)?.name || ''}
                                    </div>
                                    <div className="col-md-4 mb-2"><b className={'text-danger'}>Product
                                        group:</b> {productGroups.find((l: ProductGroupInterface) => l.productGroupNumber == setup.economic_product_group_number)?.name || ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

// @ts-ignore
export default React.memo(Economic);
